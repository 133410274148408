import React, { useState } from "react";
import "./navigation.css"; // Make sure this is the path to your CSS file
import { useUser } from "../../../containers/Dashboard/UserContext";
function Navigation({ title, button }) {
  const [isOpen, setIsOpen] = useState(false);
  const { userid } = useUser();
  return (
    <nav className="custom-navbar">
      <div className="navbar-container">
        <div className="logo-brand">
          <a href={`/dashboard?userid=${userid}`}>
          <img
            alt="Logo"
            src={require("../../../assets/logo.png")}
            className="logo"
          />
          </a>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className="toggler-icon">☰</span>
        </button>
        <div className={`navbar-menu ${isOpen ? "show" : ""}`}>
          <ul className="navbar-nav">
            {title && button && (
              <li className="nav-item-container">
                <div className="nav-title">{title}</div>
                <div className="nav-item">{button}</div>
              </li>
            )}
            {title && !button && (
              <li className="nav-item-container">
                <div className="nav-title">{title}</div>
              </li>
            )}
            {!title && button && (
              <li className="nav-item-container">
                <div className="nav-item">{button}</div>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;