import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./LoginCSS.css";
import { sendDataToBackend } from "../../services/apiService";
import { toast, Bounce } from 'react-toastify';  // Import toast and Bounce transition
import 'react-toastify/dist/ReactToastify.css';
function Login() {
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      validateData(formValues);
    }
    setIsSubmit(false);
  }, [formErrors, isSubmit]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.password) {
      errors.password = "Password is required";
    } 
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    setFormErrors(validate(formValues));
  };

  const validateData = async (formData) => {
    const endpointPath = "checklogin";
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      const responseData = await sendDataToBackend(endpointPath, JSON.stringify(formData), headers);
      if (responseData.message === "User Authenticated") {
        navigate(`/dashboard?userid=${responseData.userid}`);
        window.location.reload();
      } else {
        // Show an alert if the credentials are incorrect
      
        toast.error('Try again! Username/password is wrong.', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
          transition: Bounce,
          });
        
      }
    } catch (error) {
      // Handle any errors that occur during the API call
      console.error("Failed login:", error);
      

      toast.error("Login failed. Please try again later.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
    }
  };

  return (
    <div className="login-container">
      <div className="login-card">
        <div className="login-content">
          <h2 className="login-heading">Log In</h2>
          <p className="login-subheading">Please enter your credentials</p>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email address</label>
              <input
                type="text"
                name="email"
                placeholder="Email"
                value={formValues.email}
                onChange={handleChange}
                className="form-control"
              />
              <p className="error-text">{formErrors.email}</p>
            </div>
            <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formValues.password}
                onChange={handleChange}
                className="form-control"
              />
              <p className="error-text">{formErrors.password}</p>
            </div>
            <button type="submit" className="login-button">
              Log In
            </button>
          </form>
          <p className="signup-text">
            Don't have an account? <Link to="/signin">Sign Up</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
