import Home from "./containers/Home/Home";
import Upload from "./containers/Upload/Upload";
import Login from "./containers/Login/Login";
import Signin from "./containers/Signin/Signin";
import Dashboard from "./containers/Dashboard/Dashboard";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { UserProvider } from './containers/Dashboard/UserContext';  // Import UserProvider
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; 
function App() {
  return (
    <BrowserRouter>
      <UserProvider> {/* Wrap UserProvider around your routes */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signin" element={<Signin />} />
          <Route path="/upload" element={<Upload />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
        <ToastContainer />
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
