import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Tooltip
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { fetchDataFromBackend } from "../../../services/api_GETservice";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#000",
    color: theme.palette.common.white,
    borderBottom: '1px solid #ededed',
    fontWeight: 'bold',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: "#c6c6c6",
    border:"none"
  },
  borderBottom: 'none',
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  borderBottom: 'none',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));
function Row(props) {
  const { row, index } = props; // Added index to props
  const [open, setOpen] = useState(false);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
    }, (err) => {
      console.error('Failed to copy: ', err);
    });
  };
  return (
    <React.Fragment>
      <StyledTableRow hover sx={{ cursor: 'pointer' }}>
        <StyledTableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon sx={{color:"white"}}/> : <KeyboardArrowDownIcon sx={{color:"white"}} />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          {`Transaction ${index + 1}`} {/* Changed to dynamic index based on row */}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.dateTime}
        </StyledTableCell>
        <StyledTableCell align="center">
          {`Image ${index + 1}`} {/* Changed to dynamic index based on row */}
        </StyledTableCell>
        <StyledTableCell align="center">
          {row.result}
        </StyledTableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, background: '#f7f7f7', borderRadius: '', padding: '16px', boxShadow: '0 2px 4px rgba(0,0,0,0.1)', backgroundColor:"#000" }}>
              <Typography variant="h6" gutterBottom component="div" color={"white"}>
                Transaction Details
              </Typography>
              <Table size="small" aria-label="details">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell>Transaction Hash</StyledTableCell>
                    <StyledTableCell>
                      {row.blockHash} 
                      <IconButton onClick={() => copyToClipboard(row.blockHash)} size="small" sx={{ ml: 1, color:"white" }}>
                        <Tooltip title="Copy">
                          <ContentCopyIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Block Number</StyledTableCell>
                    <StyledTableCell>
                    {row.blockNumber} {/* Added block number */}
                      <IconButton onClick={() => copyToClipboard(row.blockNumber)} size="small" sx={{ ml: 1, color:"white" }}>
                        <Tooltip title="Copy">
                          <ContentCopyIcon fontSize="small" />
                        </Tooltip>
                      </IconButton>
                    </StyledTableCell>
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell>Image URL</StyledTableCell>
                    <StyledTableCell>
                      <Button
                        variant="outlined"
                        component="a"
                        href={row.imgUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{
                          textTransform: 'none',
                          color: '#f4cb83',
                          borderColor: '#f4cb83',
                          borderWidth: 2,
                          borderStyle: 'dashed',
                          '&:hover': {
                            borderColor: '#f4cb83',
                            backgroundColor: 'black',
                            borderStyle: 'dashed',
                            borderWidth: 2,
                          },
                          padding: '4px 12px',
                          fontSize: '1rem',
                          borderRadius: '25px',
                          marginRight: '8px',
                          textDecoration: 'none'
                        }}
                      >
                        View Image
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
function CollapsibleTable() {
    const [data, setData] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
          const endpointPath = "transaction";
          try {
              const result = await fetchDataFromBackend(endpointPath);
              setData(result || []);
          } catch (error) {
              console.error("Failed to fetch data:", error);
              setData([]);
          }
      };
      fetchData();
  }, []);
  if (!data || data.length === 0) {
    return (
        <Box sx={{ width: '80%', margin: 'auto', mt: 4, borderColor: "white" }}>
            <Typography variant="h6" sx={{ color: 'white', textAlign: 'center' }}>No data available</Typography>
        </Box>
    );
}
  return (
    <Box sx={{ width: '80%', margin: 'auto', mt: 4 }}>
      <TableContainer component={Paper} sx={{
        maxHeight: 500,
        overflow: 'auto',
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
        '& .MuiPaper-root': {
          borderRadius: 2,
          border: '2px solid white',
        }
      }}>
        <Table stickyHeader aria-label="collapsible table" sx={{backgroundColor:"#000", borderCollapse: 'collapse', borderSpacing: '0'}}>
          <TableHead>
            <TableRow>
              <StyledTableCell />
              <StyledTableCell>Transaction Number</StyledTableCell>
              <StyledTableCell align="center">Date & Time</StyledTableCell>
              <StyledTableCell align="center">Image Name</StyledTableCell>
              <StyledTableCell align="center">Result</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <Row key={row.blockNumber} row={row} index={index} /> 
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
    // test commit 
  );
}
export default CollapsibleTable;