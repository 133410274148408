// Import necessary components and hooks
import React, { useState, useEffect } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Navigation from "../../components/common/navigation/navigation";
import Footer from "../../components/common/footer/footer";
import { fetchDataFromBackend } from "../../services/api_GETservice";
import "./DashboardCSS.css"; // Make sure to include the updated CSS styles here
import CollapsibleTable from "../../components/common/accordion/accordion";
import { useUser } from './UserContext';  // Import useUser hook
import { toast, Bounce } from 'react-toastify';  // Import toast and Bounce transition
import 'react-toastify/dist/ReactToastify.css';
const Dashboard = () => {
  const urlParams = new URLSearchParams(window.location.search);
  //const userid = urlParams.get("userid");
  const { userid, setUserid } = useUser();  // Use the useUser hook
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const useridFromParams = urlParams.get("userid");
    if (useridFromParams) {
      setUserid(useridFromParams);
    } else if (!userid) {
      // If userid is not present in URL and context, redirect to login
      navigate('/login');
    }
  }, [location.search, navigate, userid, setUserid]);

  const handleUpload = () => {
    navigate(`/upload?userid=${userid}`);
    window.location.reload();
  };
 
  const handleLogout = async () => {
    const endpointPath = "logout";
    try {
      const data = await fetchDataFromBackend(endpointPath);
      //alert(data.message);
      toast.info(data.message +"!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });
      navigate("/");
    } catch (error) {
      console.error("Failed to send data:", error);
    }
  };

  const logoutButton = (
    <button className="logoutButton" onClick={handleLogout}>
      Logout
    </button>
  );

  return (
    <div className="site-container"> {/* Flex container */}
      <div className="content-container"> {/* Content wrapper */}
        <Navigation title={`Welcome, ${userid}`} button={logoutButton} />
        <div className="container">
          <div className="buttons">
            <button className="btn" onClick={handleUpload}>
              Upload Image
            </button>
          </div>
        </div>
        <h1 className="dash">Transaction History</h1>
              <CollapsibleTable />
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
