import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import Lottie from "react-lottie";
import animationData1 from "../../assets/upload.json";
import "./UploadCSS.css";
import Footer from "../../components/common/footer/footer";
import Navigation from "../../components/common/navigation/navigation";
import { sendDataToBackend } from "../../services/apiService";
import { fetchDataFromBackend } from "../../services/api_GETservice";
import { toast, Bounce } from 'react-toastify';  // Import toast and Bounce transition
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions1 = {
  loop: true,
  autoplay: true,
  animationData: animationData1,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const Upload = () => {
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [isDropzoneLeft, setIsDropzoneLeft] = useState(false);
  const [result, setResult] = useState("");
  const [isPreviewVisible, setIsPreviewVisible] = useState(true);
  const [isResultVisible, setIsResultVisible] = useState(false);

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    // Check if the file type is one of the allowed formats
    if (
      file &&
      (file.type === "image/png" ||
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/svg+xml")
    ) {
      setSelectedImage(file);
      setFiles([
        {
          ...file,
          preview: URL.createObjectURL(file),
        },
      ]);
      // Reset position on new drop
      setIsDropzoneLeft(false);
    } else {
      // Display an alert if the file format is not allowed
     
      toast.error('Please upload an image in PNG, JPG, JPEG, or SVG format.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
        });

    }
  }, []);
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation(); 
    const formData = new FormData();
    formData.append("image", selectedImage);

    const endpointPath = "classify";

    try {
      const data = await sendDataToBackend(endpointPath, formData);
      setResult(data.message);
      setIsResultVisible(true);
      setIsDropzoneLeft(true); // Move dropzone to the left
    } catch (error) {
      console.error("Failed to send data:", error);
    }
  };

  const handleImageClick = () => {
    // Center dropzone when a previewed image is clicked
    setIsDropzoneLeft(false);
  };

  const handleCancel = () => {
    // Reset dropzone position and result visibility
    setIsDropzoneLeft(false);
    setResult("");
  };

  const handleAddtoBlockchain = async (e) => {
    const endpointPath = "addtoblockchain";

    try {
      const data = await fetchDataFromBackend(endpointPath);
      toast.info(data.message + '!', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        transition: Bounce,
      });
      navigate(`/dashboard?userid=${userid}`);
      //window.location.reload();
    } catch (error) {
      console.error("Failed to send data:", error);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
  });

  const handleReUpload = async (e) => {
    setIsDropzoneLeft(false);
    navigate(`/upload?userid=${userid}`);
    window.location.reload();
  };

  const urlParams = new URLSearchParams(window.location.search);
  const userid = urlParams.get("userid");

  const handleLogout = async (e) => {
    const endpointPath = "logout";

    try {
      const data = await fetchDataFromBackend(endpointPath);
      alert(data.message);
      navigate("/");
    } catch (error) {
      console.error("Failed to send data:", error);
    }
  };

  const logoutButton = (
    <button className="logoutButton" onClick={handleLogout}>
      Logout
    </button>
  );

  return (
    <>
      <Navigation title={`Welcome, ${userid}`} button={logoutButton} />

      <div className="upload-container">
      

        <div
          {...getRootProps()}
          className={`dropzone ${isDragActive ? "active" : ""} ${
            isDropzoneLeft ? "dropzone-left" : ""
          }`}
        >
          {files.length === 0 && (
            <>
              <Lottie options={defaultOptions1} height={200} width={200} />
              <input {...getInputProps()} />
              {isDragActive ? (
                <p>Drop the images here ...</p>
              ) : (
                <p>Drag 'n' drop some images here, or click to select images</p>
              )}
            </>
          )}

          {files.map((file) => (
            <div
              key={file.name}
              className={`preview-image ${!isPreviewVisible ? "fade-out" : ""}`}
              onClick={handleImageClick}
            >
              <img src={file.preview} alt="Preview" />
              <p>{file.name}</p>
            </div>
          ))}

          {files.length > 0 && (
            <div className="button-group">
              <button onClick={handleReUpload} className="submit-button">
                Re-upload Image
              </button>
              <button onClick={handleSubmit} className="submit-button">
                Analyze Image
              </button>
            </div>
          )}
        </div>


        {result && (
          <div className="result">
            <h2 className="resulthead">{result}</h2>
            <div className="button-group">
              <button
                onClick={handleCancel}
                className="submit-button"
                style={{ alignItems: "center" }}
              >
                Cancel
              </button>
              <button onClick={handleAddtoBlockchain} className="submit-button">
                Add to Blockchain
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
};

export default Upload;
