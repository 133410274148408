import React from "react";
import "./footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faSquareXTwitter, faInstagram } from '@fortawesome/free-brands-svg-icons';
import footerLogo from '../../../assets/footer_logo.png'

function Footer() {
  return (
    <footer className="footer">
      <div className="container-footer">
        <div className="footer-left">
          <img src={footerLogo} alt="Block Convey Logo" />
        </div>
        <div className="footer-right">
          <div className="social-icons">
            <a href="https://www.linkedin.com/company/block-convey-llc/" aria-label="LinkedIn">
              <FontAwesomeIcon icon={faLinkedin} size="2x" /> 
            </a>
            <a href="https://x.com/blockconvey?s=20" aria-label="Twitter">
              <FontAwesomeIcon icon={faSquareXTwitter} size="2x" /> 
            </a>
            <a href="https://www.instagram.com/blockconvey/" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
          </div>
          <div className="email-address">
            <a href="mailto:info@blockconvey.com"><span>info@blockconvey.com</span></a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
